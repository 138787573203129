.price-calculator {
  z-index: 1;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $main-color;
  color: $text-color;
  font-size: 16px;
  max-width: 1024px;
}
.price-calculator__title {
  align-self: center;
  text-align: center;
  font-size: 20px;
  width: 280px;
  padding-bottom: 10px;
}
.price-calculator__info {
  font-size: 18px;
  padding-bottom: 10px;
}
.price-calculator-label {
  padding-top: 10px;
}
.price-calculator__input {
  display: flex;
  align-items: center;
  border: 1px solid rgba($text-additional, 0.8);
  height: 35px;
  margin-bottom: 10px;
  width: 100%;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  z-index: 9999;
  &:active {
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
  }
}
.price-calculator__span {
  padding: 5px;
}
.price-calculator__list {
  margin: 0;
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  li {
    margin: 0;
    padding-left: 5px;
    height: 0;
    opacity: 0;
  }
}
.price-calculator-open {
  height: 220px;
  border: 2px solid rgba($text-additional, 0.8);
  border-top: none;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  margin-top: -14px;
  margin-bottom: 10px;
  li {
    height: 30px;
    height: 100%;
    opacity: 1;
    border-bottom: 2px solid rgba($text-additional, 0.3);
    display: flex;
    align-items: center;
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
.price-calculator__item-active {
  background-color: $text-additional;
  color: $main-color;
}
.price-range__hair-volume {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.price-range__hair-width {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.price-range__const {
  text-decoration: underline;
  padding-top: 15px;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .price-calculator {
    padding: 40px;
    font-size: 20px;
  }
  .price-calculator__title {
    font-size: 24px;
    padding-bottom: 20px;
  }
  .price-calculator__input {
    width: 40%;
  }
  .price-calculator__list {
    width: 40%;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .price-calculator {
    padding: 70px;
    max-width: 1024px;
  }
  .price-calculator__title {
    font-size: 28px;
    padding-bottom: 40px;
  }
  .price-calculator__info {
    text-align: justify;
    font-size: 22px;
  }
  .price-calculator-label {
    font-size: 18px;
  }
  .price-calculator__input {
    width: 40%;
    font-size: 18px;
  }
  .price-calculator__list {
    width: 40%;
    font-size: 18px;
  }
  .price-range__hair-volume {
    font-size: 18px;
    gap: 10px;
    padding-bottom: 10px;
  }
  .price-range__hair-width {
    font-size: 18px;
    gap: 10px;
  }
  .price-range__const {
    font-size: 22px;
    padding-top: 30px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .price-calculator {
    padding: 70px;
    max-width: 1024px;
  }
  .price-calculator__title {
    font-size: 32px;
    padding-bottom: 40px;
  }
  .price-calculator__info {
    font-size: 24px;
  }
  .price-calculator-label {
    font-size: 20px;
  }
  .price-calculator__input {
    width: 40%;
    font-size: 20px;
  }
  .price-calculator__list {
    width: 40%;
    font-size: 20px;
  }
  .price-range__hair-volume {
    font-size: 20px;
    gap: 10px;
    padding-bottom: 10px;
  }
  .price-range__hair-width {
    font-size: 20px;
    gap: 10px;
  }
  .price-range__const {
    font-size: 28px;
    padding-top: 30px;
  }
}
