@import './styles/fonts/fonts.scss';
@import './styles/variables.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './component/PopupImage/PopupImage.scss';
@import './component/App/App.scss';
@import './component/Header/Header.scss';

@import './component/LinkMenu/LinkMenu.scss';
@import './component/LinkBurger/LinkBurger.scss';
@import './component/Burger/Burger.scss';
@import './component/Menu/Menu.scss';

@import './component/MainSection/MainSection.scss';
@import './component/MainSectionAbout/MainSectionAbout.scss';
@import './component/MainImage/MainImage.scss';
@import './component/SliderSection/SliderSection.scss';
@import './component/PriceSection/PriceSection.scss';
@import './component/PriceItem/PriceItem.scss';
@import './component/PricePopup/PricePopup.scss';
@import './component/PriceCalculator/PriceCalculator.scss';
@import './component/StudioSection/StudioSection.scss';
@import './component/MapSection/MapSection.scss';
@import './component/SwiperMain/SwiperMain.scss';
@import './component/WrapperIframe/WrapperIframe.scss';
@import './component/Action/Action.scss';

@import './component/EmblaCarousel/EmblaCarousel.scss';

body {
  position: relative;
  margin: 0;
  font-family:'Doloman', -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
ul {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}
span {
  display: block;
}
button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
  border: none;
  font-family: 'Doloman';
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-family: 'Doloman';
}
.wrapper-dark {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $main-color;
  position: relative;
  @include shadow-dark;
}
.wrapper-white {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $secondary-color;
}
