.burger-item {
  display: block;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 200px;
  border-radius: 1rem;
  background-color: inherit;
  color: $text-color;
  transition: color 0.5s ease;
  &:hover {
    color: $secondary-color;
    transition: color 0.5s ease;
  }
}
