.main-section {
  box-sizing: border-box;
  max-width: 1024px;
  position: relative;
  background-color: $main-color;
  width: auto;
  display: grid;
  justify-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  padding: 70px 0px 0px 0px;
}
.main-section__title {
  font-size: 20px;
  width: 78px;
  align-self: center;
  justify-self: center;
  text-align: center;
}
.main-setcion__button-more {
  grid-row: span 1;
  grid-column: span 3;
  z-index: 9;
  border-radius: 0.7rem;
  color: $primary-color;
  @include shadow-dark;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
  transition: 0.3s;
  height: 40px;
  font-size: 16px;
  &:hover {
    background-color: $text-additional;
    color: $main-color;
  }
}
.main-section__button-rollup {
  grid-row: span 1;
  grid-column: span 3;
  z-index: 9;
  border-radius: 0.7rem;
  @include shadow-dark;
  color: $primary-color;
  background-color: inherit;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
  transition: 0.3s;
  font-size: 16px;
  &:hover {
    background-color: $text-additional;
    color: $main-color;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .main-section {
    width: 100%;
    padding: 120px 70px 0px 70px;
    gap: 15px;
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .main-section__title {
    font-size: 32px;
    width: 150px;
  }
  .main-setcion__button-more {
    font-size: 22px;
    height: 60px;
  }
  .main-section__button-rollup {
    font-size: 22px;
    height: 60px;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .main-section {
    width: 70%;
    padding: 120px 70px 0px 70px;
    gap: 30px;
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
  .main-section__title {
    font-size: 32px;
    width: 150px;
  }
  .main-section__about {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-template-rows: none;
  }
  .main-section__info-title-wrapper {
    flex-direction: column-reverse;
    grid-row-start: 2;
    grid-row-end: span 1;
    grid-column-start: 1;
    grid-column-end: 2;
    gap: 70px;
  }
  .main-setcion__button-more {
    font-size: 24px;
    margin-bottom: -15px;
    height: 50px;
  }
  .main-section__button-rollup {
    height: 50px;
    font-size: 24px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .main-section {
    max-width: 1280px;
    width: 60%;
    padding: 120px 70px 0px 70px;
    gap: 25px;
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .main-section__title {
    font-size: 32px;
    width: 150px;
  }
  .main-setcion__button-more {
    font-size: 28px;
    height: 60px;
  }
  .main-section__button-rollup {
    height: 60px;
    font-size: 28px;
  }
}
