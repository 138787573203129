.iframe {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}
.wrapper__video {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper-icon {
  position: absolute;
  top: 46%;
  left: 46%;
  height: 15%;
  &:hover {
    cursor: pointer;
  }
}
.wrapper__iframe {
  width: 100%;
  height: 100%;
  position: relative;
}
.wrapper-img {
  width: 100%;
  border-radius: 1rem;
}
