.main-image__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  @include shadow-card;
  border-radius: 1.1rem;
}
.main-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  &:hover {
    cursor: pointer;
  }
}
.main-image__container {
  height: 180px;
  width: 110px;
  position: relative;
  z-index: 2;
}
.main-image-open {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: 0.5s;
  height: 25px;
  width: 25px;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    fill: $button-color;
  }
}

@media (min-width: 549px) and (max-width: 809px) {
  .main-image__wrapper {
    width: 100%;
    height: 100%;
  }
  .main-image__container {
    width: 100%;
    height: 280px;
    max-height: none;
  }
  .main-image {
    height: 100%;
    width: 100%;
  }
  .main-image-open {
    top: 20px;
    right: 150px;
    height: 34px;
    width: 34px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .main-image__wrapper {
    width: 100%;
    height: 100%;
  }
  .main-image__container {
    width: 100%;
    height: 360px;
    max-height: 360px;
  }
  .main-image {
    height: 100%;
    width: 100%;
  }
  .main-image-open {
    top: 20px;
    right: 150px;
    height: 34px;
    width: 34px;
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .main-image__wrapper {
    width: 100%;
    height: 100%;
  }
  .main-image__container {
    width: 100%;
    height: 320px;
    max-height: none;
  }
  .main-image {
    height: 100%;
    width: 100%;
  }
  .main-image-open {
    top: 20px;
    right: 150px;
    height: 34px;
    width: 34px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .main-image__container {
    width: 100%;
    height: 360px;
    max-height: none;
  }
  .main-section__master-img {
    width: 360px !important;
  }
}
