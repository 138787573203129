.menu-item__wrapper {
  opacity: 0;
  transform: translateY(-400px);
  transition: opacity 0.5s, transform 0.5s;
  transition-delay: 1s;
}
.menu-item {
  display: block;
  border-radius: 0.55rem;
  transition: 0.5s ease;
  color: $text-color;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  &:hover {
    -webkit-box-shadow: 3px 8px 8px 0px rgba(103, 99, 100, 0.2);
    -moz-box-shadow: 3px 8px 8px 0px rgba(103, 99, 100, 0.2);
    box-shadow: 3px 8px 8px 0px rgba(103, 99, 100, 0.2);
    background-color: inherit;
  }
}
.menu-item__wrapper-show {
  opacity: 1;
  transform: translateY(0);
}
.menu-item__wrapper-show:nth-child(1) {
  transition-delay: 0.2s;
}
.menu-item__wrapper-show:nth-child(2) {
  transition-delay: 0.4s;
}
.menu-item__wrapper-show:nth-child(3) {
  transition-delay: 0.6s;
}
.menu-item__wrapper-show:nth-child(4) {
  transition-delay: 0.8s;
}
.menu-item__wrapper-show:nth-child(5) {
  transition-delay: 1s;
}
.menu-item__wrapper-show:nth-child(6) {
  transition-delay: 1.2s;
}
.menu-item__wrapper-show:nth-child(7) {
  transition-delay: 1.4s;
}

/* Добавьте другие элементы и задержки, если необходимо */

@media (min-width: 768px) and (max-width: 1365px) {
  .menu-item {
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 4px;
    width: 100%;
    &:hover {
      background-color: $text-additional;
      color: $main-color;
      @include shadow-dark;
    }
  }
}
@media (min-width: 1365px) and (max-width: 1600px) {
  .menu-item {
    text-align: center;
    font-size: 18px;
    padding: 4px 12px;
    width: 100%;
    &:hover {
      background-color: $text-additional;
      color: $main-color;
      @include shadow-dark;
    }
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .menu-item {
    text-align: center;
    font-size: 22px;
    padding: 4px 16px;
    width: 100%;
    &:hover {
      background-color: $text-additional;
      color: $main-color;
      @include shadow-dark;
    }
  }
}
