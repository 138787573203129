.popup-image__wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: $title-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.popup-image__wrapper-show {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: 0.5s;
}
.popup-image__container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
  width: 85%;
  opacity: 0;
  border: 2px solid $text-additional;
  border-radius: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: 0.5s;
}
.popup-image__container-show {
  height: 80%;
  opacity: 1;
  transition: 0.5s;
}
.popup-image {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  opacity: 1;
  z-index: 9999;
}
.popup-close {
  width: 50px;
  height: 50px;
}

@media (min-width: 549px) and (max-width: 809px) {
  .popup-image__container-show {
    height: 80vh;
  }
  .popup-image {
    width: 350px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .popup-image__container-show {
    height: 80vh;
    width: 80vw;
    top: 13vh;
    left: 13vw;
    opacity: 1;
    transition: 0.5s;
  }
  .popup-image {
    box-sizing: border-box;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
    opacity: 1;
    z-index: 9999;
  }
}
@media (min-width: 1366px) and (max-width: 100vw) {
  .popup-image__container-show {
    height: 90vh;
    width: 95vw;
    top: 4vh;
    left: 2.5vw;
    opacity: 1;
    transition: 0.5s;
  }
  .popup-image {
    box-sizing: border-box;
    width: 700px;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
    opacity: 1;
    z-index: 9999;
  }
}
