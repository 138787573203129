    .embla {
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 70%;
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .embla__fullview{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    --slide-height: 33rem;
    padding-left: 1rem;
  }
  .embla__background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;
  }
  .embla__close{
    position: relative;
    width: 50px;
    height: 50px;
    margin-left: 1rem;
    margin-bottom: 1rem;
    border: 2px solid var(--detail-medium-contrast);
    border-radius: 1.8rem;
    &::after{
        position: absolute;
        content: '';
        transform: rotate(45deg);
        width: 70%;
        height: 2px;
        background: var(--text-body);
    }
    &::before{
        position: absolute;
        content: '';
        transform: rotate(-45deg);
        width: 70%;
        height: 2px;
        background: var(--text-body);
    }
  }
  .embla__viewport {
    overflow: hidden;
    padding-bottom: 2rem;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
  .embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
  }
  .embla__img{
    width: 100%;
    height: 100%;
    border-radius: 1.8rem;
    object-fit: cover;
    @include shadow-card
  
  }
  
  .embla__controls {
    display: none;

    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }
  .embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
  }
  .embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
  }
  .embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
  }
  .embla__img{
    &:hover{
        cursor: pointer;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .embla__fullview{
        --slide-height: 45rem;
    }
    .embla__controls{
        display: grid;
    }
  }

    @media (min-width: 1024px) and (max-width: 1366px) {
        .embla{
            max-width: 60rem;
        }
        .embla__fullview{
            --slide-height: 65rem;
        }
        .embla__controls{
            display: grid;
        }
    }


  @media (min-width: 1366px) and (max-width: 1600px) {
    .embla{
        grid-column-end: 7;
        max-width: 55rem;
        --slide-height: 27rem;
      }
    .embla__fullview{
        --slide-height: 40rem;
    }
    .embla__controls{
        display: grid;
    }
  }
  @media (min-width: 1600px) and (max-width: 100vw) {
    .embla{
      grid-column-end: 7;
      max-width: 74rem;
      --slide-height: 30rem;    
    }
    .embla__fullview{
        --slide-height: 50rem;
    }
    .embla__controls {
        margin-top: 0;
    }
    .embla__controls{
        display: grid;
    }
  }