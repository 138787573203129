.studio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.studio-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.studio-empty-space {
  display: block;
  height: 5px;
  width: 100%;
}
.studio-info {
  font-size: 18px;
  text-align: justify;
}

.studio-svk-img {
  @include shadow-dark;
  border-radius: 0.7rem;
  width: 100%;
}
.studio-ples-img {
  @include shadow-dark;
  border-radius: 1rem;
  width: 100%;
}
.studio-service-img {
  @include shadow-dark;
  border-radius: 1rem;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .studio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    padding: 40px;
  }
  .studio-title {
    grid-column: span 2;
  }
  .studio-info-wrapper {
    flex-direction: row;
    width: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-end: 3;
    gap: 10px;
  }
  .studio-info {
    font-size: 20px;
    text-align: center;
    min-width: 240px;
    align-self: flex-start;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .studio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    padding: 40px;
    max-width: 1024px;
  }
  .studio-title {
    grid-column: span 2;
  }
  .studio-info-wrapper {
    flex-direction: row;
    width: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-end: 3;
    gap: 10px;
  }
  .studio-info {
    font-size: 24px;
    text-align: center;
    align-self: flex-start;
  }

  .studio-svk-img {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .studio-service-img {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .studio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    padding: 40px;
    max-width: 1024px;
  }
  .studio-title {
    grid-column: span 2;
  }
  .studio-info-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-end: 3;
    gap: 10px;
  }
  .studio-info {
    font-size: 24px;
    text-align: center;
    align-self: flex-start;
    width: 100%;
  }

  .studio-svk-img {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .studio-service-img {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
