.navbar {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
}
.navbar-toggler {
  width: 40px;
  height: 40px;
  transition: 0.3s ease-in-out;
  padding: 0;
  border: 1px solid $text-color;
  border-radius: 0.3rem;
  z-index: 9;
  &:hover {
    background-color: inherit;
  }
  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.container-fluid {
  justify-content: flex-end !important;
}

.collapse {
  -webkit-box-shadow: 0px 6px 8px 0px rgba(103, 99, 100, 0.2);
  -moz-box-shadow: 0px 6px 8px 0px rgba(103, 99, 100, 0.2);
  transition: 0.3s;
}
.collapse-show {
  box-shadow: 0px 6px 8px 0px rgba(103, 99, 100, 0.2);
  height: 310px;
  .collapse-menu {
    height: auto;
  }
}
.collapse-hide {
  box-shadow: none;
  height: 0px;
  transition-delay: 0.3s;
  .collapse-menu {
    height: 0px;
  }
}

.collapse-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.collapse-menu__item.animated-item {
  animation: slideInFromRight 0.5s ease both;
}
.collapse-menu__item-hide {
  animation: slideOutToRight 0.5s ease both;
}

/* Разные задержки для каждого элемента */
.collapse-menu__item:nth-child(1).animated-item {
  animation-delay: 0.2s;
}

.collapse-menu__item:nth-child(2).animated-item {
  animation-delay: 0.4s;
}

.collapse-menu__item:nth-child(3).animated-item {
  animation-delay: 0.6s;
}

.collapse-menu__item:nth-child(4).animated-item {
  animation-delay: 0.8s;
}

.collapse-menu__item:nth-child(5).animated-item {
  animation-delay: 1s;
}

.collapse-menu__item:nth-child(6).animated-item {
  animation-delay: 1.2s;
}
.collapse-menu__item:nth-child(7).animated-item {
  animation-delay: 1.4s;
}

/* Разные задержки для каждого элемента при закрытии */
.collapse-menu__item.collapse-menu__item-hide {
  animation-delay: 0.1s;
}

/* Icon 1 */
.animated-icon1 {
  width: 35px;
  height: 35px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 80%;
  border-radius: 9px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon1 span {
  background: $text-color;
}

.animated-icon1 span:nth-child(1) {
  top: 8px;
}

.animated-icon1 span:nth-child(2) {
  top: 16px;
}

.animated-icon1 span:nth-child(3) {
  top: 24px;
}

.animated-icon1.open span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: 60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
