.action {
  position: relative;
  max-width: 1024px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
  align-items: center;
}
.action-title {
  color: $text-color;
}
.action-info {
  font-size: 18px;
  width: 90%;
  color: $text-color;
  text-align: center;
}
.action-img {
  width: 100%;
}
@media (min-width: 767px) and (max-width: 1365px) {
  .action {
    padding: 50px;
  }
  .action-info {
    text-align: center;
  }
  .action-img {
    max-width: 400px;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .action-info {
    width: 768px;
    text-align: center;
    font-size: 24px;
  }
  .action-img {
    max-width: 670px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .action-title {
    font-size: 32px;
  }
  .action-info {
    font-size: 28px;
    width: 768px;
    text-align: center;
  }
  .action-img {
    max-width: 768px;
  }
}
