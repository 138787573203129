.main-section__about {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-start: 1;
  grid-column-end: 4;
  gap: 15px;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 40px;
  max-width: 1024px;
}
.main-section__about-wrapper {
  width: 100%;
  background-color: $secondary-color;
}
.main-section__info-title {
  width: 100px;
  font-size: 20px;
  align-self: center;
  justify-self: center;
  text-align: center;
}
.main-section__master-img {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100% !important;
  justify-self: center;
  border-radius: 1rem;
  object-fit: cover;
  @include shadow-card;
}
.main-section__info-text {
  font-size: 16px;
  text-align: justify;
  color: $text-color;
  grid-column-start: 1;
  grid-column-end: 4;
}

@media (min-width: 768px) and (max-width: 1365px) {
  .main-section__about {
    padding: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .main-section__about-wrapper {
    width: 100%;
    padding: 10px;
  }
  .main-section__info-title {
    font-size: 32px;
    width: 140px;
  }
  .main-section__info {
    display: grid;
    margin-top: 100px;
  }
  .main-section__info-text {
    justify-self: flex-start;
    font-size: $font-medium;
    text-align: left;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .embla {
    grid-column-end: 7;
    max-width: 55rem;
    --slide-height: 27rem;
  }
  .main-section__about-wrapper {
    width: 100%;
  }
  .main-section__about {
    box-sizing: border-box;
    display: grid;
    justify-items: start;
    align-items: center;
    grid-template-columns: repeat(6, auto);
    grid-template-rows: repeat(2, auto);
    grid-column-start: 1;
    grid-column-end: 7;
    gap: 23px;
    width: 100%;
    padding: 70px;
    max-width: 1024px;
  }

  .main-section__master-img {
    max-height: 500px !important;
    max-width: 650px !important;
    grid-row-start: 2;
    grid-column-start: 4;
    grid-column-end: 7;
    .main-image {
      &:hover {
        transform: scale(1) !important;
      }
    }
  }
  .main-section__master-img-random {
    width: 130px !important;
  }

  .main-section__info-title {
    font-size: 32px;
    width: 210px !important;
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
  }

  .main-section__info-text {
    font-size: 28px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .embla {
    grid-column-end: 7;
    max-width: 74rem;
    --slide-height: 30rem;
  }
  .main-section__about-wrapper {
    width: 100%;
    background-size: 55px 55px;
    background-color: #f7f0f5;
    color: $main-color;
  }
  .main-section__about {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(6, auto);
    grid-template-rows: repeat(2, auto);
    grid-column-start: 1;
    grid-column-end: 7;
    gap: 30px;
    width: 100%;
    padding: 70px;
    max-width: 1280px;
  }

  .main-section__master-img {
    max-height: 500px !important;
    max-width: 650px !important;
    grid-row-start: 2;
    grid-column-start: 4;
    grid-column-end: 7;
    .main-image {
      &:hover {
        transform: scale(1) !important;
      }
    }
  }
  .main-section__master-img-random {
    width: 130px !important;
  }

  .main-section__info-title {
    font-size: 32px;
    width: 210px !important;
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
  }

  .main-section__info-text {
    font-size: 28px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 700px;
  }
}
