.price-section {
  width: 100%;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.price-section__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  width: 90%;
  max-width: 500px;
}
.price-section__title {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  font-size: 18px;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .price-section {
    width: 100%;
    background-color: $secondary-color;
  }
  .price-section__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px;
    gap: 30px;
    max-width: 820px;
  }
  .price-section__title {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: 28px;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .price-section {
    width: 100%;
    background-color: $secondary-color;
    max-width: 1024px;
  }
  .price-section__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px;
    gap: 20px;
    max-width: 1024px;
  }
  .price-section__title {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: 28px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .price-section {
    width: 100%;
    background-color: $secondary-color;
    max-width: 1024px;
  }
  .price-section__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 40px;
    gap: 30px;
    max-width: none;
  }
  .price-section__title {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: 32px;
  }
}
