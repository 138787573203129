.map {
  width: 90%;
  padding: 20px;
  padding-bottom: 40px;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  color: $text-color;
}
.map-button {
  border: 1px solid $text-additional;
  border-radius: 1rem;
  background-color: inherit;
  color: $text-color;
  z-index: 1;
  transition: 0.3s;
  min-height: 30px;
  &:hover {
    background-color: $text-additional;
    color: $main-color;
  }
}
.map-geo {
  margin-left: 10px;
  margin-bottom: -3px;
}
.map-button-link {
  display: block;
  width: 100%;
  font-size: 20px;
}
.map-wrapper-info {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  font-size: 22px;
}
.map-title {
  align-self: center;
  padding-bottom: 20px;
  font-size: 22px;
}
.map-info {
  align-self: center;
  padding-bottom: 20px;
  text-decoration: underline;
}
.map-city-info {
  padding-bottom: 10px;
  font-size: 18px;
}
.map-savinsk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $text-additional;
  border-radius: 1rem;
  padding: 10px;
}
.map-pleseck {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $text-additional;
  border-radius: 1rem;
  padding: 10px;
}
.map-telefon {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.map-telefon-link {
  text-decoration: underline;
  color: #081d1e;
}

.map-vk {
  align-self: center;
  text-align: center;
  padding: 20px;
}

@media (min-width: 1366px) and (max-width: 100vw) {
  .map {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    max-width: 1024px;
  }
  .map-title {
    font-size: 32px;
  }

  .map-vk {
    max-width: 600px;
    text-align: justify;
    font-size: 24px;
  }
  .map-wrapper-info {
    max-width: none;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .map-info {
    font-size: 24px;
  }
  .map-telefon {
    font-size: 24px;
  }
  .map-city-info {
    font-size: 24px;
  }
  .map-savinsk {
    grid-row-start: 2;
    grid-column-start: 1;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      @include shadow-dark;
    }
  }
  .map-pleseck {
    grid-row-start: 2;
    grid-column-start: 2;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      @include shadow-dark;
    }
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
}
