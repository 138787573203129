.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
@media (min-width: 768px) and (max-width: 1366px) {
  .menu {
    padding: 10px;
    gap: 10px;
    :nth-child(8) {
      margin-left: 20px;
    }
  }
}

@media (min-width: 1600px) and (max-width: 100vw) {
  .menu {
    padding: 20px !important;
    :nth-child(8) {
      margin-left: 20px;
    }
  }
}
