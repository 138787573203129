.header {
  width: 100%;
  background-color: $main-color;
  position: fixed;
  z-index: 100;
  left: 0;
  @include shadow-dark;
}
@media (min-width: 809px) and (max-width: 100vw) {
  .header {
    position: fixed;
    z-index: 100;
    padding: 16px;
  }
}
