.price-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 10px;
  gap: 10px;
  transition: 0.3s;
  z-index: 1;
  color: $text-color;
  background-color: $main-color;
  @include shadow-card;
  &:hover {
    transition: 0.3s;
    transform: scale(1.05);
  }
}
.price-item__title {
  min-width: 100px;
  font-size: 15px;
  width: min-content;
  text-align: center;
}
.price-item__img {
  width: 100%;
  height: 180px;
  border-radius: 0.8rem;
  object-fit: cover;
}
.price-item__wrapper-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.price-item__wrapper-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.price-item__button-popup {
  width: 100%;
  border: 1px solid $text-additional;
  border-radius: 1rem;
  z-index: 1;
  transition: 0.3s;
  color: $text-color;
  min-height: 30px;
  &:hover {
    background-color: $text-additional;
    color: $main-color;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .price-item {
    padding: 15px;
  }
  .price-item__img{
    height: 240px;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .price-item {
    padding: 20px;
    gap: 20px;
  }
  .price-item__title {
    font-size: 22px;
  }
  .price-item__price {
    font-size: 18px;
  }
  .price-item__img {
    height: 300px;
  }
  .price-item__button-popup {
    font-size: 22px;
  }
  .price-item__wrapper-info {
    gap: 10px;
  }
  .price-item__wrapper-description {
    gap: 10px;
  }
}
@media (min-width: 1600px) and (max-width: 100vw) {
  .price-item {
    padding: 20px;
    gap: 20px;
  }
  .price-item__title {
    font-size: 22px;
  }
  .price-item__price {
    font-size: 18px;
  }
  .price-item__img {
    height: 300px;
  }
  .price-item__button-popup {
    font-size: 22px;
  }
  .price-item__wrapper-info {
    gap: 20px;
  }
  .price-item__wrapper-description {
    gap: 20px;
  }
}
