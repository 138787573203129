$main-color: #f0f0f0;
$text-color: #231f20;
$text-additional: #b4b2b2;
$title-color: #f7e2e1;
$secondary-color: #f0f0f0;
$primary-color: #242630;
$button-color: #dcc5db;
$font-small-ultra: 14px;
$font-small: 16px;
$font-medium-ultra: 20px;
$font-medium: 24px;
$font-big: 32px;
$font-title: 48px;
@mixin shadow-dark {
  -webkit-box-shadow: 0px 0px 10px 8px rgba(103, 99, 100, 0.2);
  -moz-box-shadow: 0px 0px 10px 8px rgba(103, 99, 100, 0.2);
  box-shadow: 0px 0px 10px 8px rgba(103, 99, 100, 0.2);
}
@mixin shadow-card {
  -webkit-box-shadow: 0px 10px 10px -3px rgba(92, 85, 82, 0.5);
  -moz-box-shadow: 0px 10px 10px -3px rgba(92, 85, 82, 0.5);
  box-shadow: 0px 10px 10px -3px rgba(92, 85, 82, 0.5);
}
@mixin shadow-white {
  -webkit-box-shadow: 4px 4px 5px -2px #f7f0f5;
  -moz-box-shadow: 4px 4px 5px -2px #f7f0f5;
  box-shadow: 4px 4px 5px -2px #f7f0f5;
}
@keyframes rotateImage {
  0% {
    transform: scale(1);
    transform: rotate(0);
  }
  50% {
    transform: scale(1.1);
    transform: rotate(20deg);
  }
  100% {
    transform: scale(1.2);
    transform: rotate(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(250px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-250px);
    opacity: 0;
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-400px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes scaleButton {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}
.fixed-button {
  margin-left: 8px;
  max-width: 200px !important;
  background-color: inherit;
  animation: scaleButton ease-in-out infinite 2s;
  color: $text-color;
  font-size: 18px;
  border: 1px solid $text-color;
  border-radius: 1rem;
  padding: 5px;
  width: 150px;
  transition: 0.3s;
  z-index: 1;
  &:hover {
    background-color: $text-additional;
    color: $main-color;
  }
}
@media (min-width: 768px) and (max-width: 100vw) {
  .fixed-button {
    width: 125px;
  }
}
.theme-light {
  --brand-primary: rgb(47, 112, 193);
  --brand-secondary: rgb(116, 97, 195);
  --brand-alternative: rgb(19, 120, 134);
  --background-site: rgb(249, 249, 249);
  --background-code: rgb(244, 244, 244);
  --text-body: rgb(54, 49, 61);
  --text-comment: rgb(99, 94, 105);
  --text-high-contrast: rgb(49, 49, 49);
  --text-medium-contrast: rgb(99, 94, 105);
  --text-low-contrast: rgb(116, 109, 118);
  --detail-high-contrast: rgb(192, 192, 192);
  --detail-medium-contrast: rgb(180, 180, 180);
  --detail-low-contrast: rgb(240, 240, 242);
  --admonition-note: rgb(46, 109, 188);
  --admonition-warning: rgb(255, 196, 9);
  --admonition-danger: rgb(220, 38, 38);
  --brand-primary-rgb-value: 47, 112, 193;
  --brand-secondary-rgb-value: 116, 97, 195;
  --brand-alternative-rgb-value: 19, 120, 134;
  --background-site-rgb-value: 249, 249, 249;
  --background-code-rgb-value: 244, 244, 244;
  --text-body-rgb-value: 54, 49, 61;
  --text-comment-rgb-value: 99, 94, 105;
  --text-high-contrast-rgb-value: 49, 49, 49;
  --text-medium-contrast-rgb-value: 99, 94, 105;
  --text-low-contrast-rgb-value: 116, 109, 118;
  --detail-high-contrast-rgb-value: 192, 192, 192;
  --detail-medium-contrast-rgb-value: 234, 234, 234;
  --detail-low-contrast-rgb-value: 240, 240, 242;
  --admonition-note-rgb-value: 46, 109, 188;
  --admonition-warning-rgb-value: 255, 196, 9;
  --admonition-danger-rgb-value: 220, 38, 38;
}
